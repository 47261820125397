<template>
  <section class="press-info">
    <span class="title"><strong>Ils parlent de nous</strong></span>
    <div class="carousel-wrap" @touchstart="touchstart" @mousedown="touchstart">
      <swiper
        class="swiper"
        :options="swiperOption"
        :breakpoints="swiperOption.breakpoints"
      >
        <swiper-slide>
          <div @focus="onFocus(0)">
            <a
              class="slide"
              target="_BLANK"
              href="https://www.lefigaro.fr/economie/docubiz-la-nouvelle-reference-en-matiere-d-informations-legales-et-financieres-20210416"
            >
              <nuxt-img
                draggable="false"
                width="150"
                height="35"
                src="images/le-figaro.png"
                alt="Le Figaro - DocuBiz: la nouvelle référence en matière d'informations légales et financières"
              /></a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div @focus="onFocus(1)">
            <a
              class="slide"
              target="_BLANK"
              href="https://www.latribune.fr/supplement/docubiz-un-nouvel-arrivant-sur-le-marche-de-l-information-legale-889359.html"
            >
              <nuxt-img
                draggable="false"
                width="95"
                height="45"
                src="images/la-tribune.png"
                alt="La Tribune - Docubiz, un nouvel arrivant sur le marché de l’information légale"
              /></a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div @focus="onFocus(2)">
            <a
              class="slide"
              target="_BLANK"
              href="https://www.forbes.fr/brandvoice/docubiz-les-donnees-financieres-des-societes-en-acces-libre/"
            >
              <nuxt-img
                draggable="false"
                width="95"
                height="25"
                src="images/forbes.png"
                alt="Forbes - Docubiz: les données financières des sociétés en accès libre"
              /></a>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div @focus="onFocus(3)">
            <a
              class="slide"
              target="_BLANK"
              href="https://www.challenges.fr/entreprise/comment-docubiz-reinvente-l-acces-aux-informations-juridiques-des-entreprises_776114"
            >
              <nuxt-img
                draggable="false"
                width="150"
                height="35"
                src="images/challenges.png"
                alt="Challenges - Comment Docubiz réinvente l’accès aux informations juridiques des entreprises"
              /></a>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { directive, Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'PressInfo',
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  props: ['isMobile'],
  data () {
    return {
      swiperOption: {
        effect: 'slide',
        spaceBetween: 30,
        breakpoints: {
          1280: {
            loop: false,
            slidesPerView: 4
          },
          1001: {
            slidesPerView: 3.51,
            slidesOffsetBefore: 227,
            loop: true
          },
          701: {
            slidesPerView: 2.64,
            loop: true,
            slidesOffsetBefore: 97
          },
          639: {
            slidesPerView: 2,
            loop: true
          }
        }
      }
    }
  },
  methods: {
    touchstart (e) {
      if (!this.isMobile) {
        e.preventDefault()
      }
    },
    onFocus (i) {
      if (this.isMobile) {
        this.swiper.slideTo(i)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-wrap {
  margin-inline: auto;
  overflow: hidden;
}
.press-info {
  margin: 100px auto 0;
  max-width: 1170px;
  width: 100vw;

  .title {
    display: block;
    margin: 0;
    text-align: center;
    font-size: var(--font-size-large-s1);
    line-height: var(--line-height-large-s1);
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }

  .slide {
    width: 270px;
    height: 120px;
    max-width: 270px;
    max-height: 120px;
    background: var(--background-gray);
    border-radius: 10px;
    margin: 6px auto;
    @include flex-line-center;

    transition: all 0.25s ease;
    outline-offset: 6px;
    outline: 1px transparent solid;

    &:focus-visible {
      outline-offset: -1px;
      outline: 1px var(--brand) solid;
    }
  }
  .custom-arrow {
    --svg-color: var(--title);
    transform-origin: center;
    width: 26px;
    height: 26px;
    --max-offset-x: 6px;
    --base-offset-x: 16px;
    --max-offset-y: 0px;
    --rotate: rotate(0deg);
    --base-offset-y: 0px;
    opacity: 1;
    transition: all 0.25s ease;

    &.prev {
      --rotate: rotateZ(180deg);
    }

    svg {
      transition: all 0.25s ease;
      width: inherit;
      height: inherit;
    }

    &::before {
      content: unset;
    }

    &.prev svg {
      transform: rotateZ(180deg) translateX(var(--base-offset-x));
    }

    &.next svg {
      transform: translateX(var(--base-offset-x));
    }

    &:hover svg {
      animation: little-jump 2s linear 0s infinite normal backwards;
    }
  }

  @include phone {
    display: none;
  }
}
</style>
